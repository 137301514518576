p {
  margin: 0;
  align-self: center;
}

* {
  font-family: "Roboto",
    sans-serif;
  box-sizing:
    border-box;
  position: relative;
  word-break: normal;
}

input {
  font-family: inherit;
}

/* main{min-height: 100vh; background-color: #efefef; color: #333;} */
main {
  min-height: 100vh;
  background-color: #efefef;
  padding-top: 120px;
  color: #333;
}

.mainPage {
  min-height: 95vh;
  background-color: #efefef;
  padding-top: 75px;
  color: #333;
}

.mainLiveScreen {
  min-height: 100vh;
  padding-top: 0px;
}

.mainBigScreen {
  height: 90vh;
  background-color: #efefef;
  padding-top: 0px;
  color: #333;
}

.containerIntroduction {
  min-height: 100vh;
  padding-top: 0px;
}


button {
  -webkit-appearance: none;
  border: 0;
  outline: none;
  cursor: pointer;
}

h1,
h2,
h3,
h4 {
  font-family: 'Roboto Condensed', sans-serif;
}

ul {
  list-style: none;
}

b,
strong {
  font-weight: 700;
  font-family: inherit;
}

.bg-grey {
  background-color: #ced4da;
}

.border-grey {
  border-color: #ced4da;
}

.box-shadow {
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.15);
}

.col-3,
.col-2,
.col-4,
col-5,
col-6 {
  word-break: break-all;
}

.mr-5px {
  margin-right: 5px
}

/*;}

################### COMPONENTS ##########################
*/
/************* HEADER CLASSES ************************/
.nav-bar {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  z-index: 999;
  position: fixed;
  width: 100%;
  max-height: 68px;
  height: 68px;
  min-height: 60px;
}

.nav-bar-tab {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  height: 40px;
  z-index: 998;
  position: fixed;
  width: 100%;
  margin-top: 67px;
  margin-bottom: 5px;
  background-color: white;
  padding-top: 3px;
}

@media (max-width: 1200px) {
  .nav-bar-tab {
    display: none;
  }
}

/* This will remove the modal effect of shifting the page to the left by adding padding-right! */
body {
  margin-right: 0 !important;
  margin-left: 0 !important;
  padding-left: 0 !important;
  padding-right: 0px !important;
  /* overflow-y: auto; */
  /* background-color: green */
}

.modal-open {
  overflow-y: auto !important;
}


/* .container-tweet{border-radius: 4px; min-height: calc(98vh - 140px);} */
.container-tweet {
  border-radius: 4px;
}

#container-tweet-half {
  border-radius: 4px;
}

.nav-bar .logo img {
  max-height: 25px;
  /* margin-top: 5px; */
}

.container-tweets {
  padding: 10px;
  max-height: 90vh;
  max-width: 90%;
}

.nav-bar .event-title {
  font-size: 18px;
  font-weight: 500;
  max-height: 20px;
  word-break: normal;
  margin-bottom: 5px;
}

.nav-bar .user-profile {
  font-size: 17px;
  font-weight: 500;
  max-height: 20px;
}

.nav-bar .bigScreen {
  font-size: 17px;
  font-weight: 500;
  max-height: 20px;
  cursor: pointer;
}

.nav-bar .moderatorIcon {
  font-size: 20px;
  cursor: pointer;
}

.nav-bar .event-code-title {
  font-size: 16px;
  font-weight: 500;
  max-height: 20px;
  padding-top: 5px;
}

.nav-bar .log-out {
  font-size: 17px;
  font-weight: 500;
  max-height: 20px;
}

.nav-bar .screen-icon {
  font-size: 20px;
  font-weight: 500;
  max-height: 20px;
}

.nav-bar .roomTitleHeader {
  font-size: 18px;
  font-weight: 500;
  max-height: 20px;
  padding-top: 5px;
}

.row-moderator {
  padding-right: -15px;
}



.questionSessionTitle {
  font-size: 15px;
  font-weight: 500;
  padding-top: 5px;
}

.questionSessionSubTitle {
  font-size: 13px;
  font-weight: 500;
}



.voteNumbers {
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 3px;
  font-size: 16px;
  color: #333;
  font-weight: 500;
  padding-left: 20px;


  /* color: #00b200; */
}

.sessionSingleIcon {
  padding-right: 6px;
  padding-left: 6px;
  /* padding-top: 3px;
          padding-bottom: 3px; */
  margin-top: 3px;
  margin-bottom: 3px;
}



.screensButtonActive {
  border-radius: 20px !important;
  background-color: rgb(27, 27, 27);
  font-size: 13px;
}

.screensButtonDisable {
  border-radius: 20px !important;
  font-size: 13px;
  text-align: center;
  background-color: #8f8d8d;
}

.screensButtonGroup {
  border-color: #666666;
  background-color: #a0a6ac;
  border-radius: 40px !important;
  margin: 0px;
  padding: 2px;
  max-height: 35px;
  text-align: center;
}

#formHeader {
  width: 100%;

}

/* #tweet-tab{
    color: white;
    font-size: 20px;
}

#question-tab{
  color: red;
  font-size: 18px;
} */

#tweet-col {
  margin-right: 10px;
}



.border-bottom {
  border-bottom: 0.17em solid #007bff !important;
  box-shadow: 0px 3px 2px #888888;
  border-radius: 5px;
}

#headerNav {
  padding-bottom: 2px;
}

.itemsNav :hover {
  color: lightgrey;
  cursor: pointer;
}

.itemsNav {
  padding-left: 15px;
}

#tweet-tab .active .a {
  color: red;

}

/*************GENERAL CLASSES ************************/
.no-underline:hover {
  text-decoration: none;
}

.loader {
  border: 5px solid transparent;
  border-radius: 50px;
  height: 50px;
  opacity: 0;
  position: relative;
  width: 50px;
  animation: pulsate 0.75s ease-out;
  animation-iteration-count: infinite;
  display: block;
}

@keyframes pulsate {
  0% {
    transform: scale(.1);
    opacity: 0.0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

/************* TREE ********************************/
.e-treeview {
  background-color: #FFF;
  /* padding: 10px; */
}

.e-treeview .e-list-item.e-active>.e-fullrow,
.e-treeview .e-list-item.e-active:hover>.e-fullrow {
  background-color: #ced4da;
  border-color: #ced4da;
}

.e-fullrow:hover,
.e-fullrow:focus {
  background-color: #ced4da;
  border-color: #ced4da;
}

.e-treeview .e-icon-collapsible::before,
.e-treeview .e-icon-expandable::before {
  font-size: 13px;
}

.e-treeview .e-icon-collapsible::before,
.e-treeview .e-icon-expandable::before {
  padding: 17px;
}

.e-treeview .e-list-item .e-ul {
  padding-left: 32px;
  animation: none !important;
}

.e-treeview .e-checkbox-wrapper+.e-list-text {
  padding: 0;
}

.e-treeview .e-list-text {
  font-size: 16px;
  position: absolute;
  left: 35px;
  right: 0;
  top: 0;
}

.e-treeview .e-icon-collapsible,
.e-treeview .e-icon-expandable {
  width: 50px;
  height: 50px;
}

.e-list-parent:first-of-type {
  padding-left: 0;
}

.e-list-item[itemtype="event"]>.e-text-content span {
  font-size: 16px;
  font-weight: 500;
}

.e-treeview .item-row {
  line-height: 50px;
}

.e-treeview .item-row:before {
  font-family: "Font Awesome 5 Free";
  font-size: 26px;
  font-weight: 700;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
}

.e-treeview .item-row.event:before {
  content: "\f073";
  color: inherit;
}

.e-treeview .item-row.folder:before {
  content: "\f07b";
  color: #f0ad4e;
}

.e-treeview .item-row.tweet:before {
  content: "\f086";
  color: #5cb85c;
}

.e-treeview .item-row.question:before {
  content: "\f128";
  color: #007bff;
}

.e-treeview .item-row.survey:before {
  content: "\f022";
  color: #17a2b8;
}

.e-treeview .item-row.room:before {
  content: "\f3c5";
  color: #f52323;
}

.e-treeview .item-row.eventInfo:before {
  content: "\f1ea";
  color: #5b83db;
}

.e-treeview .item-row.library:before {
  content: "\f038";
  color: #5dc521;
}

.rst__rowTitle {
  content: "\f038";
  color: #5dc521;
}


.e-treeview .e-text-content {
  min-height: 50px;
  line-height: 50px;
  pointer-events: all !important;
  border: none;
}

.e-treeview .e-fullrow {
  height: 50px;
}

.box-item {
  background-color: #FFF;
  border-radius: 5px;
  cursor: pointer;
  transition: .3s all ease-in-out;
  border: 1px solid #f4f4f4;
  height: 98%;
  padding: 5px;
  margin-right: 5px;

}

.box-item:hover {
  border-color: #ced4da;
}

.box-item.folder {
  color: #f0ad4e;
}

.box-item.room {
  color: #f52323;
}

.box-item.question {
  color: #007bff;
}

.box-item.tweet {
  color: #5cb85c;
}

.box-item.survey {
  color: #17a2b8;
}

.box-item.eventInfo {
  color: #5b83db;
}

.box-item.library {
  color: #fd7e14;
}

.libraryIcon {
  /* transform:          rotate(90deg);
  -ms-transform:      rotate(90deg);
  -moz-transform:     rotate(90deg);
  -webkit-transform:  rotate(90deg);
  -o-transform:       rotate(90deg); */
}

.box-item i {
  font-size: 55px;
}

.box-item .title {
  font-size: 18px;
  font-weight: 500;
}

.box-item .description {
  color: #666666;
  font-size: 13px;
  word-break: normal;
  text-align: left;
  margin-bottom: 4px;
  margin-left: 5px;

}

.item-btn-box {
  display: none;
  z-index: 99;
  right: 8px;
  transition: .3s opacity ease-in;
}

.item-btn-box button {
  z-index: 99;
}

.item-btn-box button i {
  z-index: -1;
}

.e-treeview .e-list-item.e-active>.e-text-content .item-btn-box {
  display: table;
}

.e-checkbox-wrapper {
  display: none !important;
}

.item-row.event .showItem,
.item-row.event .editItem,
.item-row.event .removeItem,
.item-row.folder .showItem {
  display: none;
}

.item-row.question .addItem,
.item-row.tweet .addItem,
.item-row.survey .addItem {
  display: none;
}

.e-input-group {
  max-width: 300px;
  margin: 10px 50px;
}

.elementsHeader {
  font-size: 25px;
  padding: 10px;
  background-color: #b8c1ca;
  border-color: #b8c1ca;
  color: white;
  align-items: center;
}

.moveIconElements {
  color: grey;
  font-size: 20px;
  /*margin-top: 8px;*/
  cursor: move;
}

.libraryIcon {
  color: #fd7e14;
}

/************* MAIN SCREEN *************************/
.downloadResults {
  font-size: 16px;
  padding-top: 3px;
}

.modalShare {
  font-size: 16px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 5px;
  padding-top: 15px;
}

.errorMessage {
  font-size: 14px;
  color: red;
  padding-top: 4px;
  padding-left: 8px;
  font-weight: 410;
}

.showSharedEmails {
  font-size: 14px;
  padding-top: 4px;
  padding-left: 8px;
  font-weight: 410;
  cursor: pointer;
  border-bottom: 1px;
}

.shareEmailIcons {
  font-size: 21px;

}

.eventsTab {
  font-size: 16px;
  font-weight: 500;
}

.emailPlanLink {
  color: white;
  text-decoration: none;

}

.emailPlanLink:hover {
  text-decoration: none;
  color: white;
}

.emailPlanModal {
  font-size: 16px;
}

.event-card {
  cursor: pointer;
  transition: all .3s ease-in-out;
  /* width: 90%; */
}

.event-card:hover {
  border-color: #007bff !important;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.15);
}

.event-card .event-title {
  font-size: 16px;
  color: #666666;
}

.form-control[readonly] {
  border: none;
  background-color: transparent;
  pointer-events: none;
}

.event-logo-small {
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
}


.inputLogo img {
  /* max-height: 90px; */
  max-height: 100%;
  max-width: 100%;
}

.inputLogo {
  max-width: 80%;
  max-height: 60%;
  height: 125px;
  width: 125px;
}

.form-control-color {
  -webkit-appearance: none;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: 6px 12px;
  box-sizing: content-box;
  max-width: 50%;
}

.color-code {
  color: #4e4e4e;
  font-size: 15px;
  font-weight: 500;
}

.font-no-result {
  color: #666666;
  font-size: 15px;
  font-weight: 400;
}

.invalid {
  border: 1px solid #ff0000
}

.validating {
  border: 1px solid #ced4da;
}

.eventColumnIcon {
  max-width: auto;
  width: auto;
}



#eventColumnTitle {
  max-width: auto;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400 !important;
  color: #007bff;

}

.headerSetting {
  height: 40px;
}

.titleTreeDiv {
  cursor: all-scroll;
}

.userProfileText {
  font-size: 15px;
  font-family: "Roboto", sans-serif;
}

/******************* Elements Screen ***********************************/



.eventElementRoot {
  font-family: "Roboto", sans-serif;
  padding: 10px;
  background-color: #7bb0ea !important;
  font-size: 27px;
  /* color: white; */
  margin-bottom: 15px;
  border-radius: 5px;
}

.roomElementRoot {
  font-family: "Roboto", sans-serif;
  border: 1px solid #347cca !important;
  font-size: 20px;
  margin-bottom: 10px;
  margin-left: 0px;
  margin-right: 5px;
  border-radius: 5px;
  align-items: center;
  padding: 5px;
  /* color: white; */
  background-color: #347cca !important;
}

.eventElementParent {
  font-family: "Roboto", sans-serif;
  border: 1px solid #c8d2e2 !important;
  font-size: 20px;
  margin-bottom: 13px;
  margin-left: 0px;
  margin-right: 5px;
  border-radius: 5px;
  align-items: center;
  padding: 5px;
  background-color: #c8d2e2;

}

.nestedElementParent {
  font-family: "Roboto", sans-serif;
  border: 1px solid #9eb4bd !important;
  font-size: 20px;
  margin-bottom: 14px;
  border-radius: 5px;
  align-items: center;
  padding: 8px;
  margin-left: 60px;
  margin-right: 60px;
}

.eventElementChild {
  font-family: "Roboto", sans-serif;
  border: 1px solid #bec9fc !important;
  font-size: 17px;
  margin-bottom: 12px;
  margin-left: 30px;
  margin-right: 3px;
  border-radius: 5px;
  align-items: center;
  padding: 6px;
  background-color: #bec9fc;
}

.rootElementChild {
  font-family: "Roboto", sans-serif;
  border: 1px solid #7bb0ea !important;
  font-size: 17px;
  margin-bottom: 12px;
  margin-left: 12px;
  margin-right: 5px;
  border-radius: 5px;
  align-items: center;
  padding: 6px;
  background-color: #7bb0ea !important;

}

.nestedParent2Children {
  margin-left: 70px;
  margin-right: 70px;
}


.itemsFunctionsBtn {
  color: white;
  font-size: 15px;
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 2px;
  padding-top: 2px;
  border-radius: 5px;
  margin-right: 4px;
}

.itemsFunctionsBtnDel {
  color: white;
  font-size: 15px;
  padding-left: 9px;
  padding-right: 9px;
  padding-bottom: 3px;
  padding-top: 3px;
  border-radius: 5px;
  margin-right: 4px;
}

.itemsFunctionsBtn i {
  color: white;
  font-size: 15px;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 3px;
  padding-top: 3px;
}

.itemIcons {
  font-size: 35px;
  color: #f0ad4e;
  padding-left: 3px;
  padding-top: 3px;

}

.folderIcon {
  color: #f0ad4e;
  font-size: 33px;


}



.labelFolder {
  /* padding-top: 6px; */
  padding-left: 30px;

}

.labelChild {
  padding-top: 6px;
  padding-left: 35px;

}


.iconColumn {
  flex: 0 0 4.333333%;
  max-width: 4.333333%;
}

.iconColumnChild {
  flex: 0 0 4.333333%;
  max-width: 4.333333%;
}

.childIcons {
  font-size: 30px;
}

.eventInfoIcon {
  font-size: 30px;
  color: #a8b7d1;
}

.libraryIconItem {
  font-size: 30px;
  /* transform:          rotate(90deg);
  -ms-transform:      rotate(90deg);
  -moz-transform:     rotate(90deg);
  -webkit-transform:  rotate(90deg);
  -o-transform:       rotate(90deg); */
}


.elementExpandIcon {
  flex: 0 0 3%;
  max-width: 3%;
  padding-right: 0px;

}

.parentCol11 {
  flex: 0 0 97%;
  max-width: 97%;
}

.expandIcon {
  font-size: 25px;
  color: #a8b7d1;
  padding-bottom: 10px;
  cursor: pointer;

}

.nestedElementContainer {
  padding-left: 100px;
}

.parentBox {
  background-color: red;
}

.modalEditRoom {
  padding-bottom: 20%;
  border-color: white;
}


.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #eee;
}

.checkmark {
  background-color: #2196F3;
}


.expandIconcol {
  flex: 0 0 3%;
  max-width: 3%;
  /* margin-left: 20px; */
}


.expandIconcolRoot {
  flex: 0 0 3%;
  max-width: 3%;
  margin-right: 10px;
}


/**************************** TWEET SCREEN *********************************/
.container-tweet h2 {
  font-size: 20px;
  padding-bottom: 5px;
  text-transform: uppercase;
  color: #666;
}

.container-tweet h2:after {
  content: "";
  display: block;
  height: 3px;
  width: 50px;
  background-color: #666;
  bottom: 0;
}

.single-tweet {
  border-width: 1px;
  overflow: hidden;
  border-radius: 3px;
}

.head-single-tweet {
  line-height: 20px;
  padding-top: 11px;
  padding-bottom: 11px;
  padding-right: 0px;
}

.username-single-tweet {
  font-size: 15px;
}

.hours-single-tweet {
  font-size: 14px;
  /* margin-right: -12px; */
}

.screen-icon-tweet {
  font-size: 15px;
}

.stop-icon-tweet {
  font-size: 12px;
}

.stop-icon-tweet:focus {
  outline: none;
  box-shadow: none;
  /* border:2px solid transparent; */
}

.menu-single-tweet {
  position: absolute;
  right: 15px;
  top: 11px;
}

.menu-icon {
  font-size: 18px;
  background-color: transparent;
  color: #FFF;
  line-height: 20px;
  padding-left: 0px;
}

#fixedbutton {
  /* position: fixed; */
  bottom: 10px;
  right: 10px;
  border-radius: 50%;
}

.fixedFooter {
  position: fixed;
  bottom: 10px;
  left: 0;
  right: 0;
  z-index: 99;
}

/***************************** Home Question ******************************/
.questionOptionsList {
  margin-block-start: 0;
  margin-inline-start: 0px;
  padding-inline-start: 20px;
}

.resultIcon {
  font-size: 15px;
  padding: 3px 1px 0px 1px;
}

.optionBullet {
  font-size: 9px;
  padding-right: 6px;
}

.optionDecoration .dropdown-item:hover {
  background-color: rgb(150, 203, 247);
}

.optionDecoration {
  margin-top: -5px;
}

.optionDecorationBtn {
  padding: 2px 10px 2px 10px;
}

.optionDecorationBtnName {
  padding: 2px 50px 2px 0px;
}

.optionDecorationItem {
  padding-bottom: 3px;
  border-bottom: 1.2pt solid rgb(208, 208, 214);
}

.optionDecorationItemEx {
  font-size: 13px;
  color: #6c757d;
}

.modalSessions {
  font-size: 17px;
}

.question-card {
  /* margin: 8px; */
}

.addQuestion {
  display: inline;
}

#addQuestion :hover {
  color: #00b200;
}

.exampleModal {
  width: 30%;

}

#imagesize2 {
  width: 70px;
  height: 70px;
  /* // padding: 20px; */
}

#imagesize3 {
  width: 80px;
  height: 70px;
  padding-top: 25px;
}

#questionTextMultiple {
  width: 100%;
}

#inlineFormInputName3 {
  width: 20%;
}

#textalign {
  margin-top: 5px;
  font-size: 14px;
  word-break: break-word;
}

#stars {
  padding: 4px;
}

#trash {
  padding-left: 0px;
}

#inputsContainer {
  margin-left: 15px;
}

.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 18px;
  overflow: hidden;
  font-size: 12px;
  background-color: rgba(209, 207, 207, 0.50);
  border-radius: 3px;
  margin: 5px;
}

.questionLabel {
  margin-top: 15px;
  margin-left: 10px;
}

.option-card-body {
  margin-top: 10px;
  /* margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;  */
}

#session-card-header {
  /* display:flex;
  align-items:center; */
  /* padding-left: 20px; */
}

/* #live-button{
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
} */
#live-button[status="play"] {
  background-color: #00b200;
}

/* #live-button[status="play"]:before{
  content: "\f304";
} */
#live-button[status="stop"] {
  background-color: #f0ad4e;
}

/* #live-button[status="stop"]:before{
  content: "\f1f8";
} */

.questionIcon {
  font-size: 13px;
}

.questionTypeText {
  font-size: 13px;
  font-weight: 450;
}

.questionButtons {
  /* padding: 1px; */
  margin-left: -11px;
}

.question-containe {
  border: 0;
}

.spinOption {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 50px;
  color: #007bff;
}

.cardHeaderAnswer {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 1px;
  font-size: 15px;
}

.cardBodyAnswer {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  padding-bottom: 5px;
  font-size: 16px;
}

#cardBodyAnswerLiveScreen.cardBodyAnswer {
  font-size: 50px;
}

#optionsCard {
  margin-top: -2px;
}

.votesCard .card-header {
  padding-left: 9px;
  padding-top: 5px;
  padding-bottom: 1px;
  padding-right: 8px;
}


.votesCard .card-body {
  padding-left: 0px;
  padding-top: 5px;
  padding-bottom: 0px;
  /* padding-right: 0px; */
  padding-right: 20px;

}



.iconsMaxWidth {
  max-width: 26%;
}

.archiveIcons {
  margin-left: 27px;
  margin-top: 1px;
}

.stopLiveButton {
  color: white;
  background-color: #ffc107;
}

.archiveDesktopIcon {
  font-size: 14px;
}

.optionDivOption {
  display: -ms-flexbox;
  /* display: flex; */
  -ms-flex-align: center;
  align-items: center;
  font-size: 1vw;
  /* word-break: break-word; */
  word-break: normal;
  font-weight: 450;
}

.voteDivOption {
  display: flex;
  align-items: center;
  font-size: 1vw;
}

.votesCard .card-body-option {
  padding-left: 12px;
  padding-top: 2px;
  padding-bottom: 4px;
  padding-right: 14px;
  /* padding-right: 20px; */
  font-weight: 450;

}

#screenProgressOption {
  height: 30px;
}

.progress-bar-option {
  height: 30px;
}

/* voteDivOption .voteDivNum{   display: table;   float: left;   min-width: 13%;   padding-left: 15px; } */

/* Multiple Choice checkbox */
#correctAnswerRadioLabel::before,
#correctAnswerRadioLabel::after {
  width: 20px;
  height: 20px;
  /* margin-right: 4px; */

}

#correctAnswerRadioLabel {
  margin-bottom: 9px;
}

#correctAnswerRadioLabel .custom-control-label::after {
  /* top: 4px;
  left: -24px; */
  top: 0.1rem;
  right: 0.1rem;
  width: 1.5rem !important;
  height: 1.5rem !important;



}

.correctAnswerRadioLabel:checked~.custom-control-label::before {
  color: #fff;
  background-color: #28a745 !important;
}

#correctAnswerRadioLabel .custom-control-label::before {
  top: 4px;
  left: -24px;
  background-color: #c5c9ce;
  border-radius: 20px;
}

/* multiple choice chec box */
#multipleChoiceLabel::before,
#multipleChoiceLabel::after {
  width: 20px;
  height: 20px;
  margin-right: 4px;
}

#multipleChoiceLabel .custom-control-label::before {
  top: 4px;
  left: -24px;
  background-color: #c5c9ce;
  border-radius: 20px;
}

#multipleChoiceLabel {
  margin-bottom: 9px;
}

#correctAnswerRadioSimpleLabel {
  margin-bottom: 9px;
}

#correctAnswerRadioSimpleLabel .custom-control-label::after {
  /* top: 4px;
  left: -24px; */
  top: 0.1rem;
  right: 0.1rem;
  width: 1.5rem !important;
  height: 1.5rem !important;



}

.correctAnswerRadioSimpleLabel:checked~.custom-control-label::before {
  color: #fff;
  background-color: #28a745 !important;
}

.labelText {
  margin-top: 2px;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  color: inherit;
}

#eventDeleteBtn {
  padding-left: 9px;
  padding-right: 9px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.eventColumnLogo {
  height: 70px;
  width: 70px;
  justify-content: center;
  align-items: center;
  padding: 6px;
  cursor: move;
}

.dragableContainer {
  cursor: move;
}

.questionFunctionsContainer {
  cursor: pointer;
}

.questionsingleIcon {

  max-height: 28px !important;

}

.questionIcons {
  margin-left: 12px;
  align-items: flex-end;

}


#questionsList {
  padding: 0;
  list-style-type: none;
}

body>li {
  list-style: none;
}

#correctAnswerModalAlert {
  padding: 0px;
}

.alertAnswerBtn {
  height: 35px;
  /* width: 120px; */
  font-size: 12px;
  color: black;
  margin-top: 7px;
  font-family: "Roboto", sans-serif;

}

.alertText {
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  margin: 4px;
}

.alertDialog {
  padding-top: 5px;
  padding-bottom: 170px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: rgba(51, 51, 51, 0.51);
}

.tippy-popper .tippy-tooltip {
  position: relative;
  color: rgb(14, 13, 13);
  border-radius: 6px;
  /* font-size: .75rem; */
  padding: .4rem .8rem;
  text-align: center;
  will-change: transform;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: rgb(212, 211, 211);
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 410;

}

.tippy-popper .arrow-regular {
  border-top: 7px solid rgb(200, 200, 200) !important;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  bottom: -7px;
  margin: 0 9px;
}

.cardTooltip {
  background-color: rgb(231, 226, 226);
}

.linkTooltip {
  text-decoration: none;
}


.optionsDragableRow {
  cursor: move;
  border-top-color: #007bff
}

.dragOptionsbtn {
  cursor: move;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: white;
  font-size: 20px;
  color: #c2c0c0;
}

.dragOptionsbtn :hover {
  color: #616161;
}

.optionsRow {
  border-color: #d6d6d6 !important;
  border-width: 2px;
  padding-left: 5px;
  padding-right: 9px;
  padding-top: 3px;
  padding-bottom: 0px;
  border-radius: 5px;

}

.iconClose {
  border-radius: 20px;
  font-size: 17px;
  color: white;
  margin-top: 4px;
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 1px;
}

.closeBtnSession {
  background-color: #888888;
  margin-right: 5px;
  margin-top: 3px;
}

.showCorrectanswerColorBtn {
  background-color: #28a745;
  padding-right: 5px;
  padding-left: 5px;
  margin-top: 3px;
  margin-bottom: 5px;


}

.showColorIcon {
  font-size: 16px;
  color: white;
}

.showResults {
  margin-top: 3px;
  padding-bottom: 1px;
}

/* .closeBtnSession :focus {
  outline: none;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
} */


.addQuestionRoundbtn {
  border-radius: 24px;
  /* padding: 5px; */
  /* margin: 2px; */
  /* padding: .07rem .25rem; */
  padding-bottom: 1px;
  padding-top: 1px;
  padding-left: 6px;
  padding-right: 6px;
  border-color: #9b9c9c;
  color: #9b9c9c;
  background-color: transparent;
}

.addQuestionRoundbtn:hover {
  background-color: #9b9c9c;
  color: white;
}

.eventElementsCol-1 {
  flex: 0 0 5%;
  max-width: 5%;
}

.commentQuestionType {
  font-size: 55px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.ratingStartsType {
  font-size: 1.2vw;
  padding-top: 21px;
  padding-bottom: 21px;
  padding-left: 1px;
  padding-right: 1px;

}

.questionModalsDialog {
  padding-bottom: 20%;
}

.questionTypeCards {
  cursor: pointer;

}


.optionLikeIcon {
  font-size: 27px;
  padding-right: 10px;
}

.optionLikeIconBigScreen {
  font-size: 67px;
}

.labelLikeSize {
  font-size: 18px;
}

/********************************* Library Screen *******************************/
.filesListTitle {
  font-size: 18px;
  cursor: pointer;
}

.card-body-files-icons {
  font-size: 45px;
  padding: 3px;
}

.card-body-files {
  font-size: 1.2rem;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 500;
  padding: 18px;
}

.inputFileDropZone {
  height: 120px;
  width: 120px;
  border: #d4d4d4;
  background-color: #d8d6d6;
  border-style: dashed;
  border-radius: 5px;
  border-width: 2px;
  justify-content: center;
  align-items: center;
}

.logoEditModalImageAgenda {
  max-width: 50px;
  max-height: 50px;
  display: inline-block;
  vertical-align: middle;
  float: none;
  margin: 0 auto;
  border: none;
  margin-top: 6px;

}

.agenda-upload-Text {
  font-size: 13px;
  line-height: 15px;
  margin-top: 6px;

}

.fileListHeader {
  font-size: 30px;

}

/****************************** Event Info Screen *******************************/
.optionAddIcon {
  font-size: 30px;
}

.eventInfoDropZone {
  height: 120px;
  width: 120px;
  border: #d4d4d4;
  background-color: #d8d6d6;
  border-style: dashed;
  border-radius: 5px;
  border-width: 2px;
  justify-content: center;
  align-items: center;
}

/********************************* EDIT MODAL ***********************************/
.e-input-group .e-control-wrapper .e-date-wrapper:after {}

.e-date-wrapper .e-input-group-icon.e-date-icon.e-icons :before {
  /* csslint allow: adjoining-classes*/
}

#element {
  max-width: 100%;
  margin: 0 auto;
}


.form-control-eventCode {

  border-radius: .25rem !important;

}

#inputDate {
  background-color: white;
  max-width: 310px;
  width: 310px;
  border-radius: 0px;
}

#dateIcon {
  margin-left: 2px;
  margin-right: 2px;
}

#qrCodeImage {
  width: 100px !important;
  height: 100px !important;
  /* margin-left: 10px; */
  margin-top: 10px;
}

.qrCodeImageLibrary {
  width: 62px !important;
  height: 62px !important;
  vertical-align: middle;
  /* margin-left: 10px; */
  /* margin-top: 10px; */
}


#downloadLink {
  color: white;
  text-decoration: none;
}

#hidePopUpBtn {
  border: 0px;
  margin-right: 10px;
  height: 40px;
  width: 80px;
}

#saveButton {
  border: 0px;
  height: 40px;
  width: 90px;
}

.switch {
  position: relative;
  display: table;
  padding: 1px;
  float: right;
  border-radius: 30px;
  background-color: #666;
  width: 175px;
}

.inputOrder {
  transition: .4s left ease-in-out;
  position: absolute;
  height: 17px;
  width: 17px;
  /* bottom: 4px; */
  /* background-color: white; */
  border-radius: 50%;
  left: 5px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.textToggleOrder {
  font-size: 15px;
  font-family: "Roboto", sans-serif;

}

/* .textToggleOrder{padding-left: 8px; padding-right: 11px; transition: all .4s ease-in-out;} */
.textToggleOrder {
  transition: all .6s ease-in-out;
}

.switch.recent .textToggleOrder {
  font-size: 15px;
  font-family: "Roboto", sans-serif;

}

.switch.recent .inputOrder {
  left: 153px;
}

.switch input {
  display: none;
}

#removeBtnShadow {
  border: none;
  background: transparent;
  outline: none;
  padding: 0px;
  margin-bottom: 2px;
  padding-top: 4px;
}

.tweetsToShow .switch {
  position: relative;
  display: table;
  padding: 2px;
  float: right;
  border-radius: 30px;
  background-color: #666;
  width: 122px;
}

.tweetsToShow .switch.recent .inputOrder {
  left: 100px;
}

.inputOrder {
  transition: .6s left ease-in-out;
  position: absolute;
  height: 17px;
  width: 17px;
  /* bottom: 4px; */
  /* background-color: white; */
  border-radius: 50%;
  left: 5px;
  top: 0;
  bottom: 0;
  margin: auto;
}

#voteResultsDisplay {
  font-size: 14px;
  font-family: "Roboto", sans-serif;

}

/****** Edit Modal (Question Tab) ************/
.editModalLabels {
  font-size: 16px;
  font-weight: 500;
}

.surveyLinkAddress {
  word-break: normal;
}

.flagImages {
  margin-bottom: 3px;
  margin-right: 1px;
  width: 40px;
  height: 14px;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #156cb9;
  border-color: white;
}

.btn-secondary {
  color: #6c757d;
  background-color: lightgray;
  border-color: white;
}

#btnToggle {
  height: 30px;
  width: 55px;
  font-size: 13px;
  padding: 3px;
  /* padding-top:5px;  */

}

#editTitleFont {
  font-family: "Roboto", sans-serif;
  /* margin-left: 10px; */
  margin-right: 20px;
  margin-top: 3px;
}

.logoEditModal {
  /* height: 100%;
  width: 50%; */
  border: 1px transparent;
  margin: 0 auto;
}


.switchsOffEdit {
  cursor: pointer;
  left: 4px;
  right: 25px;
  bottom: 4px;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
  top: 1px;
  position: relative;
  display: inline-block;
  width: 88px;
  height: 23px;
}

.switchOnEdit {
  content: "";
  left: 4px;
  right: 25px;
  bottom: 4px;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
  cursor: pointer;
  /* background-color: rgb(138, 135, 135); */
  top: 1px;
  position: relative;
  display: inline-block;
  width: 88px;
  height: 23px;
}

.inputOnEdit {
  position: absolute;
  height: 16px;
  width: 22px;
  bottom: 4px;
  /* background-color: white; */
  border-radius: 50%;
  margin-left: 61px;
}

.inputOffEdit {
  position: absolute;
  height: 16px;
  width: 22px;
  bottom: 4px;
  /* background-color: white; */
  border-radius: 50%;
  margin-left: 4px;
}

.textToggleOffEdit {
  margin-top: 2px;
  margin-right: 8px;
  font-size: 12px;
}

.textToggleOnEdit {
  margin-top: 1px;
  margin-left: 17px;
  font-size: 14px;
}


#btnToggleTweetsToShow {
  height: 30px;
  width: 70px;
  font-size: 13px;
  padding: 3px;
  /* padding-top: 5px; */
}

.footerPadding {
  padding-bottom: 30px;
}


#datepickerStart {
  width: 291px;
  border: none;
}

#datepickerStart textarea:focus,
input:focus {
  outline: none;
}

#datepickerEnd {
  width: 291px;
  border: none;
}

#datepickerEnd textarea:focus,
input:focus {
  outline: none;
}

.dragAndDropArea {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 0;
  background-color: green
}

#LogoText {
  font-size: 11px;
  margin-top: 8px;
  word-break: normal;
  color: rgb(100, 97, 97);
  align-items: flex-start;
  font-family: "Roboto", sans-serif;
  margin-left: 4px;
  justify-content: baseline
}

#imageSelectNote {
  font-size: 14px;
  margin-top: 10px;
  padding-left: 30px;
  word-break: normal;
  color: rgb(100, 97, 97);
  align-items: flex-start;
  font-family: "Roboto", sans-serif;
}


.inputLogoBigScreen {
  /* max-width: 60%;
    max-height: 60%; */
  /* height: 100px; */
  /* width: 120px; */
  align-items: center;
  padding: 2px;
  /* background-color: #1b3957 */
  /* margin-left: 40px; */
}



.logoBox {
  width: 80px;
  height: 80px;
  border: #d4d4d4;
  background-color: #d8d6d6;
  border-style: dashed;
  border-radius: 5px;
  border-width: 2px;
  justify-content: center;
  /* align-items: center; */
  /* margin-left: 67px; */
  cursor: pointer;
}


.splashBox {
  width: 110px;
  height: 110px;
  border: #d4d4d4;
  background-color: #d8d6d6;
  border-style: dashed;
  border-radius: 5px;
  border-width: 2px;
  justify-content: center;
  align-items: center;
  /* margin-left: 48px; */
  cursor: pointer;
}


.dragAndDropZone {
  height: 140px;
  width: 140px;
  border: none;
}



.logoEditModalImage {
  max-width: 70px;
  max-height: 70px;
  display: inline-block;
  vertical-align: middle;
  float: none;
  margin: 0 auto;
  border: none;
}



.splashEditModalImage, .homeEditModalImage {
  max-width: 100px;
  max-height: 100px;
  /* width: 120px; */
  /* max-height: 100px; */
  display: inline-block;
  vertical-align: middle;
  float: none;
  margin: 0 auto;
  border: none;
}

.imagePreviewThemeBigScreen {
  max-width: 100px;
  max-height: 100px;
  display: inline-block;
  vertical-align: middle;
  float: none;
  margin: 0 auto;
  border: none;
}

.imagePreviewThemeParticipant {
  max-width: 100px;
  max-height: 100px;
  display: inline-block;
  vertical-align: middle;
  float: none;
  margin: 0 auto;
  border: none;
}

.closeButtonImage {
  position: absolute;
  z-index: 999;
  top: -1px;
  right: 2px;
}

.closeImageIcon {
  /* background-color: black; */
  /* margin: 2px; */
  border-radius: 20%;
  font-size: 16px;
  color: rgb(233, 58, 58)
}

.removeLinkIcon {
  font-size: 22px;
}

.removeLinBtn {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 6px;
  padding-right: 6px;
}

/*********************** Agenda Screen **************************************/
.fileNameFont {
  font-size: 16px;
}

.linkIcon {
  font-size: 30px;
}

/******************************************** BIG SCREEN *********************************/

.optionComparison {
  padding-top: 30px;
  padding-bottom: 20px;
  font-size: 1.8vw;
  line-height: 1.9vw;
}


.cardAnswetText {
  border: none;
}

.imageTheme {
  background-repeat: no-repeat, repeat;
  background-size: 100%;
  background-position-y: bottom;
  min-height: 90vh;
  max-height: 90vh;
  overflow-y: auto;
}

.progressBarDiv {
  display: flex;
  align-items: center;
  padding-right: 15px;
}

.voteDivNum {
  display: table;
  float: left;
  min-width: 13%;
  padding-left: 15px;
}


.loaderContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -80px;
}

/* .introductionContainer{
  position: fixed;
  top: 12%;
  left: 35%;
  margin-top: -50px;
  margin-left: -80px;
} */

.introductionTitle {
  font-size: 60px;
  font-weight: 700;
  text-align: center;
}

.introductionContent {
  font-size: 90px;
  font-weight: 700;
  text-align: center;
}

.introductionContent2 {
  font-size: 60px;
  font-weight: 700;
  text-align: center;
}

.introductionContent3 {
  font-size: 80px;
  font-weight: 700;
  text-align: center;
}

#qrCodeImage2 {
  width: 300px !important;
  height: 300px !important;
  /* margin-left: 10px; */
  margin-top: 30px;
  /* left: 30%; */
}

.rowQrCode {
  justify-content: center;
  align-items: center;
}

.centerCard {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -200px;
  width: 400px;
}



.footerBigScreen {
  height: 5vh;
}

.headerBigScreen {
  height: 10vh;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
}

.containerMiddle {
  min-height: 85vh;
  padding-left: 30px;
  padding-right: 20px;
}

#containerMiddle {
  height: 85vh;
  padding-left: 30px;
  padding-right: 20px;
  line-height: 30px;
  /* overflow-y: scroll; */
  /* font-size: 4vw; */

}

.containerMiddleTweet {
  height: 85vh;
  padding-left: 30px;
  padding-right: 20px;
  line-height: 35px;
  /* overflow-y: scroll; */
  font-size: 4vw;
}


.optionDiv {
  display: flex;
  align-items: center;
  /* font-size: 16px;  */
  /* font-size: 2vw; */
  /* word-break: break-word; */
  word-break: normal;
  /* margin-right: 10px; */
}

.progressBarDiv {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0px;
}

.voteDiv {
  /* display: -ms-flexbox; */
  display: flex;
  /* -ms-flex-align: center; */
  align-items: center;
  /* font-size: 3vw; */
  /* margin-left: 10px; */
  /* padding: 0px;  */
}


#padingColumnBig {
  width: 2, 5%;
}

.LiveScreenContainer {
  min-height: 100vh;
  z-index: 999;
  width: 100%;
  padding: 0px;
  margin: 0px;
  padding-top: 10vh;
}

.logoHeader {
  height: 10vh;
}

.toggleBtnBigScreen {
  height: 10vh;
  margin-top: 5px;
  margin-right: 5px;

}

.logoHeader img {
  max-height: 90%;
  border: none;
  margin-right: 10px;
}

.bigScreenTitle {
  font-size: 45px;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  word-break: break-word;
  width: 100%;
}

.bigScreenTitle.Moderator {
  font-size: 45px;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  word-break: break-word;
  width: 80%;
}

.bigScreenVoteNo {
  font-size: 30px;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  word-break: break-word;
}

#screen-eventd-data {
  color: white;
}

.big-screen-row {
  margin: 0;
}

.screen-title {
  /* font-size: 90px; */
  font-weight: 100px;
  height: 30%;
  color: white;

}

.screen-box {
  display: table-cell;
  vertical-align: middle;
}

.screen-option {
  /* font-size: 40px; */
  color: white;
  height: 70%;
  /* border: 20px; */
  width: 70%;
}

#event-Data {
  font-size: 40px;
  color: white;

}

#no-live-session {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: 190px;
}

.headerTextStyle {
  font-size: 38px;
  padding-bottom: 5px;
  text-transform: uppercase;
}

.borderLine {
  content: "";
  display: block;
  height: 3px;
  width: 70px;
  /* background-color: #666; */
  bottom: 0;
}

.tweetsToShowBigScreen .switch {
  position: relative;
  display: table;
  padding: 2px;
  float: right;
  border-radius: 30px;
  background-color: #666;
  width: 122px;
}

.switch .textToggleOrderBigScreen {
  font-size: 15px;
  font-family: "Roboto", sans-serif;

}

.switch.recent .textToggleOrderBigScreen {
  font-size: 15px;
  font-family: "Roboto", sans-serif;

}

.tweetsToShowBigScreen .switch.recent .inputOrderBigScreen {
  left: 100px;
}

.inputOrderBigScreen {
  transition: .6s left ease-in-out;
  position: absolute;
  height: 18px;
  width: 18px;
  /* bottom: 4px; */
  /* background-color: white; */
  border-radius: 50%;
  left: 5px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.loadingChart {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 50px;
  color: #007bff;
  position: fixed;
  top: 50%;
  left: 30%;
}


/************** Big Screen Tweet **************************************/
.container-tweet-BigScreen {
  border-radius: 4px;
  min-height: calc(98vh);
}

.container-tweet-BigScreen h2 {
  font-size: 20px;
  padding-bottom: 5px;
  text-transform: uppercase;
  color: #666;
}

.container-tweet-BigScreen h2:after {
  content: "";
  display: block;
  height: 3px;
  width: 50px;
  background-color: #666;
  bottom: 0;
}





.switch input {
  display: none;
}

.switchOFF {
  cursor: pointer;
  left: 4px;
  right: 25px;
  bottom: 4px;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
  top: 1px;
  position: relative;
  display: inline-block;
  width: 90px;
  height: 27px;
}

.switchON {
  content: "";
  left: 4px;
  right: 25px;
  bottom: 4px;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
  cursor: pointer;
  /* background-color: rgb(138, 135, 135); */
  top: 1px;
  position: relative;
  display: inline-block;
  width: 90px;
  height: 27px;
}

.inputOFF {
  position: absolute;
  height: 19px;
  width: 24px;
  bottom: 4px;
  /* background-color: white; */
  border-radius: 50%;
  margin-left: 4px;
}

.inputON {
  position: absolute;
  height: 19px;
  width: 24px;
  bottom: 4px;
  /* background-color: white; */
  border-radius: 50%;
  margin-left: 62px;
}

.textToggleOff {
  margin-top: 3px;
  margin-right: 23px;
  font-size: 14px;
  font-weight: 600px;
}

.textToggleOn {
  margin-top: 3px;
  margin-left: 5px;
  font-size: 13px;
  font-weight: 600px;
}


/************** Login page **************************************/
#registerationButton {
  margin-top: 30px;
}

.app-version {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

/* .main-registeration{min-height: 100vh; background-color: red; padding-top: 0px; color: #333;} */

#alertError {
  margin-top: -100px;
}

.customSize {
  width: 140px !important;
}

/************************ Header Tab Page *********************************/
.eventsTabs {
  /* font-size: 14px;
  color: #635b5b; 
  font-weight: 600; */
  margin: 4px;
  font-size: 82%;
  font-weight: 550;
  cursor: pointer;
}

.dropDownContainer .eventsTabs {
  font-size: 100%;
}

.settingContainer {
  /* margin-top: 64px; */
  width: 100%;
  z-index: 1000;
  padding: 2px;
  cursor: default;
  background-color: white;

}

.navBarText {
  margin: 4px;
  font-size: 82%;
  font-weight: 550;
}

.menuItem {
  cursor: pointer;
  background-color: white;
  border-bottom: 2px solid;
  border-bottom-color: #034286;
  word-break: normal;
}

.tabCol {
  margin-right: 15px;
  /* max-width: 12%;
  padding: 0px;
  word-break: normal; */
}

.tabColQuestion {
  margin-right: 15px;

}

.tabCol3 {
  margin-right: 20px;
}



/******************************Survey Page************************************/

.viewOptionsIcon {
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 1px;
}

.surveyTitle {
  font-size: 24px;
  text-transform: uppercase;
  /* margin-bottom: 2px; */
  padding-bottom: 2px;
  color: #666;
}

.surveyText {
  font-size: 22px;
  padding-bottom: 3px;
  margin-left: 5px;
}

/****************************** Header Tab ************************************/


.importanceFeedback {
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
}



#messageModal .modal-dialog-centered {
  padding-bottom: 15%;
}

/************************ Participants Screen ********************************/
.participanstScreen {
  font-size: 15px;
}

/****************************** Media Tags ************************************/

@media (max-width: 320.98px) {
  .introductionTitle {
    font-size: 40px;
  }

  .introductionContent2 {
    font-size: 40px;
  }

  .introductionContent3 {
    font-size: 40px;
  }

  .dragableContainer {
    pointer-events: none;
  }

  .loaderContainer {
    position: fixed;
    top: 50%;
    left: 60%;
    margin-top: -50px;
    margin-left: -80px;
  }

  .nav-bar .event-title {
    font-size: 11px;
    font-weight: 500;
    max-height: 20px;
    word-break: normal;
    margin-bottom: 10px;
  }

  .nav-bar .event-code-title {
    font-size: 10px;
    font-weight: 500;
    max-height: 20px;
    padding-top: 5px;
  }


  .voteNumbers {
    /* margin-right: 10px;
          margin-left: 20px; */
    margin-top: 8px;
    font-size: 7px !important;
    color: #333;
    font-weight: 500;
    /* color: #00b200; */
    padding: 0px;
    padding-left: 11px;
  }

  .questionsingleIcon {
    padding-right: 6px;
    padding-left: 6px;
    padding-top: 2px;
    padding-bottom: 2px;
    max-height: 28px !important;

  }

  .sessionSingleIcon {
    padding-right: 5px;
    padding-left: 5px;
    /* padding-top: 3px;
            padding-bottom: 3px; */
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .openIcon {
    padding-right: -2px;
    padding-left: -2px;
    padding-top: 2px;
    padding-bottom: 2px;

  }

  #datepickerStart {
    width: 153px;
    border: none;
  }

  #datepickerEnd {
    width: 153px;
    border: none;
  }

  .inputLogoBigScreen {
    height: 140px;
    width: 140px;
    border: #afaaaa;
    background-color: #cacaca;
    border-style: dashed;
    border-radius: 5px;
    border-width: 2px;
    justify-content: center;
    align-items: center;
    margin-left: 42px;

  }

  .tweetsToShowBigScreen .switch {
    position: relative;
    display: table;
    padding: 2px;
    float: right;
    border-radius: 30px;
    background-color: #666;
    width: 80px;
  }

  .switch .textToggleOrderBigScreen {
    font-size: 10px;
    font-family: "Roboto", sans-serif;

  }

  .switch.recent .textToggleOrderBigScreen {
    font-size: 10px;
    font-family: "Roboto", sans-serif;

  }

  .tweetsToShowBigScreen .switch.recent .inputOrderBigScreen {
    left: 60px;
  }

  .inputOrderBigScreen {
    transition: .6s left ease-in-out;
    position: absolute;
    height: 14px;
    width: 14px;
    /* bottom: 4px; */
    /* background-color: white; */
    border-radius: 50%;
    left: 5px;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .bigScreenTitle {
    font-size: 10px;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    word-break: break-word;
  }

  .bigScreenVoteNo {
    font-size: 7px;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    word-break: break-word;
  }

  .headerBigScreen {
    height: 10vh;
    padding-left: 20px;
    padding-right: 10px;
  }

  .headerTextStyle {
    font-size: 22px;
    padding-bottom: 5px;
    text-transform: uppercase;
    font-family: "Roboto", sans-serif;
    /* color: #666; */
  }

  .cardTextTweet {
    /* padding:16px; */
    border-radius: 2px;
    font-size: 16px;
    font-weight: 400px;
  }

  .cardBodyTweetSmall {
    padding: 8%;
  }

  .cardTextTweetBig {
    padding: 10%;
  }

  .eventTitleText {
    font-size: 20px;
  }

  .eventTitleCode {
    font-size: 18px;
  }

}

@media (min-width: 321px) and (max-width: 576px) {
  .introductionTitle {
    font-size: 40px;
  }

  .introductionContent2 {
    font-size: 40px;
  }

  .introductionContent3 {
    font-size: 40px;
  }

  .eventElementRoot {
    font-family: "Roboto", sans-serif;
    padding: 10px;
    background-color: #a8b7d1;
    font-size: 24px;
    color: white;
    margin-bottom: 15px;
    border-radius: 5px;
  }

  .dragableContainer {
    pointer-events: none;
  }

  .roomElementRoot {
    font-family: "Roboto", sans-serif;
    border: 1px solid #749bdafa !important;
    font-size: 20px;
    margin-bottom: 10px;
    margin-left: 0px;
    margin-right: 5px;
    border-radius: 5px;
    align-items: center;
    padding: 5px;
    background-color: #749bdafa;
  }

  .eventElementParent {
    font-family: "Roboto", sans-serif;
    border: 1px solid #c8d2e2 !important;
    font-size: 15px;
    margin-bottom: 10px;
    margin-left: 0px;
    margin-right: 5px;
    border-radius: 5px;
    align-items: center;
    padding: 5px;
    background-color: #c8d2e2;

  }

  .nestedElementParent {
    font-family: "Roboto", sans-serif;
    border: 1px solid #9eb4bd !important;
    font-size: 20px;
    margin-bottom: 10px;
    border-radius: 5px;
    align-items: center;
    padding: 6px;
    margin-left: 60px;
    margin-right: 60px;
  }

  .eventElementChild {
    font-family: "Roboto", sans-serif;
    border: 1px solid #bec9fc !important;
    font-size: 14px;
    margin-bottom: 7px;
    margin-left: 30px;
    /* margin-right: 40px; */
    border-radius: 5px;
    align-items: center;
    padding: 7px;
    background-color: #bec9fc;
  }

  .rootElementChild {
    font-family: "Roboto", sans-serif;
    border: 1px solid #749bdafa !important;
    font-size: 17px;
    margin-bottom: 7px;
    margin-left: 1px;
    margin-right: 2px;
    border-radius: 5px;
    align-items: center;
    padding: 5px;
    background-color: #749bdafa;

  }

  .eventNodesContainer {
    padding-left: 0px !important;
  }

  .iconColumn {
    flex: 0 0 5%;
    max-width: 5%;
  }

  .iconColumnChild {
    flex: 0 0 12%;
    max-width: 12%;
  }

  .expandIconcol {
    flex: 0 0 11%;
    max-width: 11%;
  }

  .labelChild {
    padding-top: 4px;
    /* padding-left: 35px; */
  }

  .tabCol {
    margin-right: 10px;
    margin-top: 2px;
    word-break: normal;
    max-width: 10%;
  }

  #container-tweet-half {
    border-radius: 4px;
  }

  .container-tweet {
    border-radius: 4px;
    min-height: 10px;
    margin-bottom: 20px;
  }



  .voteNumbers {
    /* margin-right: 10px;
    margin-left: 20px; */
    margin-top: 8px;
    font-size: 8px !important;
    color: #333;
    font-weight: 500;
    /* color: #00b200; */
    padding: 0px;
    padding-left: 11px;
  }

  .questionsingleIcon {
    padding-right: 7px;
    padding-left: 7px;
    padding-top: 3px;
    padding-bottom: 3px;
    max-height: 28px !important;

  }

  .sessionSingleIcon {
    padding-right: 5px;
    padding-left: 5px;
    /* padding-top: 3px;
      padding-bottom: 3px; */
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .navbarCol {
    -ms-flex: 0 0 10%;
    flex: 0 0 10%;
    max-width: 20%;

  }

  #headerEventTitle {
    font-size: 10px;
    margin-bottom: 7px;
    word-wrap: none;
  }

  .nav-bar .logo img {
    max-height: 25px;
    /* margin-top: 7px; */

  }

  .card-columns {
    column-count: 3;
  }

  .cardTextTweet {
    /* padding:16px; */
    border-radius: 2px;
    font-size: 16px;
    font-weight: 400px;
  }

  .cardBodyTweetSmall {
    padding: 10%;
  }

  .cardTextTweetBig {
    padding: 15%;
  }

  .eventTitleText {
    font-size: 20px;
  }

  .eventTitleCode {
    font-size: 18px;
  }

  .react-datepicker-wrapper .react-datepicker__input-container input {
    border: #ced4da;
    width: 203px;
    margin-right: 5px;
    /* margin: 10px 50px; */
  }

  .optionDivOption {
    display: -ms-flexbox;
    /* display: flex; */
    -ms-flex-align: center;
    align-items: center;
    font-size: 4vw;
    /* word-break: break-word;
   */
    word-break: normal;

    font-weight: 450;
  }

  .voteDivOption {
    display: flex;
    align-items: center;
    font-size: 3vw;
  }

  #screenProgressOption {
    height: 23px;
  }

  .progress-bar-option {
    height: 23px;
  }

  .cardBodyTweetSmall {
    padding: 8%;
  }

  .cardTextTweetBig {
    padding: 12%;
  }

  .cardTextTweet {
    /* padding:16px; */
    border-radius: 2px;
    font-size: 14px;
    font-weight: 400px;
  }

  .eventTitleText {
    font-size: 16px;
  }

  .eventTitleCode {
    font-size: 14px;
  }

  /* we make the appearance on the mobile constant it does 
not depend on the number of options or length of the option's text */
  .optionDiv {
    display: flex;
    align-items: center;
    /* font-size: 3vw !important; */
    word-break: break-word;
  }

  .progressBarDiv {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 0px;
  }

  #screenProgress {
    height: 22px !important;
    width: 90% !important;
  }

  .progress-bar {
    height: 22px !important;
  }

  #datepickerStart {
    width: 205px;
    border: none;
  }

  #datepickerEnd {
    width: 205px;
    border: none;
  }

  #inputDate {
    background-color: white;
    max-width: 390px;
    width: 390px;
    border-radius: 0px;

  }

  .inputOrder {
    -webkit-transition: .6s left ease-in-out;
    -o-transition: .6s left ease-in-out;
    transition: .6s left ease-in-out;
    position: absolute;
    height: 15px;
    width: 15px;
    /* bottom: 4px; */
    /* background-color: white; */
    border-radius: 50%;
    left: 5px;
    top: 2px;
    bottom: 3px;
    margin: auto;
  }

  .switch {
    position: relative;
    display: table;
    padding: 3px;
    float: right;
    border-radius: 30px;
    background-color: #666;
    width: 148px;
  }

  .textToggleOrder {
    font-size: 12px;
    font-family: "Roboto", sans-serif;
  }

  .switch.recent .textToggleOrder {
    font-size: 12px;
    font-family: "Roboto", sans-serif;
  }

  .switch.recent .inputOrder {
    left: 128px;
  }

  #voteResultsDisplay {
    font-size: 12px;
    font-family: "Roboto", sans-serif;

  }

  .inputLogoBigScreen {
    height: 140px;
    width: 140px;
    border: #d4d4d4;
    background-color: #ededed;
    border-style: dashed;
    border-radius: 5px;
    border-width: 2px;
    justify-content: center;
    align-items: center;
    margin-left: 68px;
  }

  .eventsTabs {
    font-size: 48%;
    font-weight: 500;
    cursor: pointer;
  }

  .navBarText {
    font-size: 50%;
    font-weight: 500;
  }

  .border-bottom {
    border-bottom: 0.17em solid #007bff !important;
    -webkit-box-shadow: 0px 3px 2px #888888;
    box-shadow: 0px 3px 2px #888888;
    border-radius: 5px;
  }





  .tabColQuestion {
    /* margin-right:17px; */
    word-break: normal;
    max-width: 14%;
  }

  .nav-bar-tab {
    -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    height: 32px;
    z-index: 998;
    position: fixed;
    width: 100%;
    margin-top: 60px;
    margin-bottom: 5px;
    background-color: white;
  }

  .tweetsToShowBigScreen .switch {
    position: relative;
    display: table;
    padding: 2px;
    float: right;
    border-radius: 30px;
    background-color: #666;
    width: 65px;
  }

  .switch .textToggleOrderBigScreen {
    font-size: 8px;
    font-family: "Roboto", sans-serif;

  }

  .switch.recent .textToggleOrderBigScreen {
    font-size: 8px;
    font-family: "Roboto", sans-serif;

  }

  .tweetsToShowBigScreen .switch.recent .inputOrderBigScreen {
    left: 50px;
  }

  .inputOrderBigScreen {
    transition: .6s left ease-in-out;
    position: absolute;
    height: 14px;
    width: 14px;
    /* bottom: 4px; */
    /* background-color: white; */
    border-radius: 50%;
    left: 3px;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .headerTextStyle {
    font-size: 28px;
    padding-bottom: 5px;
    text-transform: uppercase;
    /* color: #666; */
  }

  .bigScreenTitle {
    font-size: 11px;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    word-break: break-word;
  }

  .bigScreenVoteNo {
    font-size: 8px;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    word-break: break-word;
  }

  .headerBigScreen {
    height: 10vh;
    padding-left: 15px;
    padding-right: 10px;
  }

  .filesListTitle {
    font-size: 12px;
    cursor: pointer;
  }

  .card-body-files-icons {
    font-size: 35px;
    padding: 3px;
  }

  .card-body-files {
    font-size: 0.7rem;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 500;
    padding: 14px;
  }


  .logoEditModalImage {
    max-width: 40px;
    max-height: 40px;
    display: inline-block;
    vertical-align: middle;
    float: none;
    margin: 0 auto;
    border: none;
  }

  .inputFileDropZone {
    height: 110px;
    width: 150px;
    border: #d4d4d4;
    background-color: #d8d6d6;
    border-style: dashed;
    border-radius: 5px;
    border-width: 2px;
    justify-content: center;
    align-items: center;
  }

  .fileListHeader {
    font-size: 20px;
  }


  .loaderContainer {
    position: fixed;
    top: 50%;
    left: 60%;
    margin-top: -50px;
    margin-left: -50px;
  }

}


/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .tabCol {
    margin-right: 14px;
  }

  .navbarCol {
    -ms-flex: 0 0 10%;
    flex: 0 0 10%;
    max-width: 20%;

  }

  .card-columns {
    column-count: 3;
  }

  .cardTextTweet {
    /* padding:16px; */
    border-radius: 2px;
    font-size: 16px;
    font-weight: 400px;
  }

  .cardBodyTweetSmall {
    padding: 10%;
  }

  .cardTextTweetBig {
    padding: 15%;
  }

  .eventTitleText {
    font-size: 20px;
  }

  .eventTitleCode {
    font-size: 18px;
  }

  .react-datepicker-wrapper .react-datepicker__input-container {
    border: #ced4da;
    /* width: 350px; */
    /* margin-right: 5px; */
    /* margin-left: -70px; */
    /* margin: 10px 50px; */
  }

  .react-datepicker-wrapper .react-datepicker__input-container input {
    border: #ced4da;
    /* width: 120px; */
    /* margin-right: 30px; */
    /* margin: 10px 50px; */
  }

  #inputDate {
    background-color: white;
    max-width: 392px;
    width: 392px;
    border-radius: 0px;

  }

  #datepickerStart {
    width: 131px;
    border: none;
  }

  #datepickerEnd {
    width: 131px;
    border: none;
  }

  .cardTextTweet {
    /* padding:16px; */
    border-radius: 2px;
    font-size: 14px;
    font-weight: 400px;
  }

  .cardBodyTweetSmall {
    padding: 10%;
  }

  .cardTextTweetBig {
    padding: 15%;
  }

  .eventTitleText {
    font-size: 18px;
  }

  .eventTitleCode {
    font-size: 16px;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {

  .loaderContainer {
    position: fixed;
    top: 50%;
    left: 55%;
    margin-top: -50px;
    margin-left: -40px;
  }

  .iconColumn {
    flex: 0 0 9.5%;
    max-width: 9.5%;
  }

  .iconColumnChild {
    flex: 0 0 9.5%;
    max-width: 9.5%;
  }

  .tabCol {
    margin-right: 12px;
  }

  .expandIconcol {
    flex: 0 0 9%;
    max-width: 9%;
  }

  .navbarCol {
    -ms-flex: 0 0 10%;
    flex: 0 0 10%;
    max-width: 20%;
  }

  .react-datepicker-wrapper .react-datepicker__input-container {
    border: #ced4da;
    /* width: 350px; */
    /* margin-right: 5px; */
    /* margin-left: -70px; */
    /* margin: 10px 50px; */
  }

  #datepickerStart {
    width: 131px;
    border: none;
  }

  #datepickerEnd {
    width: 131px;
    border: none;
  }

  .cardTextTweet {
    /* padding:16px; */
    border-radius: 2px;
    font-size: 14px;
    font-weight: 400px;
  }

  .cardBodyTweetSmall {
    padding: 10%;
  }

  .cardTextTweetBig {
    padding: 15%;
  }

  .eventTitleText {
    font-size: 18px;
  }

  .eventTitleCode {
    font-size: 16px;
  }

  .bigScreenTitle {
    font-size: 20px;
    width: 80vw;
  }  

  .bigScreenTitle.Moderator {
    font-size: 20px;
    width: 60vw;
  }  
}

/* Large devices (desktops, 992px and up)  tablet landscape*/
@media (min-width: 992px) and (max-width: 1199.98px) {
  .head-single-tweet {
    line-height: 20px;
    padding-top: 11px;
    padding-bottom: 11px;
    padding-right: 10px;
  }

  .menu-single-tweet {
    position: absolute;
    right: 5px;
    top: 12px;
  }

  .screen-icon-tweet {
    font-size: 15px;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .stop-icon-tweet {
    font-size: 12px;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .stop-icon-tweet:focus {
    outline: none;
    box-shadow: none;
    /* border:2px solid transparent; */
  }

  .navbarCol {
    -ms-flex: 0 0 10%;
    flex: 0 0 10%;
    max-width: 20%;
  }

  .questionsingleIcon {
    padding-right: 7px;
    padding-left: 7px;
    padding-top: 3px;
    padding-bottom: 3px;
    max-height: 28px !important;
  }


  .iconsMaxWidth {
    max-width: 23%;
  }

  .voteNumbers {
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 3px;
    font-size: 14px;
    color: #333;
    font-weight: 500;
    padding-left: 20px;


    /* color: #00b200; */
  }

  .cardBodyTweetSmall {
    padding: 5%;
  }

  .cardTextTweet {
    /* padding:16px; */
    border-radius: 2px;
    font-size: 18px;
    font-weight: 400px;
  }

  .cardTextTweetBig {
    padding: 10%;
  }

  .eventTitleText {
    font-size: 20px;
  }

  .eventTitleCode {
    font-size: 18px;
  }

  .bigScreenTitle {
    font-size: 25px;
  }   
}


/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .headerDropdown {
    display: none;
  }

  .navbarCol {
    -ms-flex: 0 0 10%;
    flex: 0 0 10%;
    max-width: 20%;
  }

  .cardTextTweet {
    /* padding:16px; */
    border-radius: 2px;
    font-size: 20px;
    font-weight: 400px;
  }

  .cardBodyTweetSmall {
    padding: 1.7%;
  }

  .cardTextTweetBig {
    padding: 1.9%;
  }

  .eventTitleText {
    font-size: 50px;
    font-weight: 400px;
  }

  .eventTitleCode {
    font-size: 45px;
    font-weight: 400px;
  }
}

.cardTextTweetBody {
  font-size: 35px;
}

.flipped {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}



/* Alessandro */
/* 
    .parentDiv{
      float: right;
      width: 100%;
      margin-right: -69px;
    } */

/* @media (min-width: 992px) */

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {

  .container {
    max-width: 1024px;
  }


}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

  .container {
    max-width: 1024px;
  }


}


#fileInputName {
  color: #007bff;
  font-size: 1.2rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  padding: 18px;
  box-shadow: -4px -5px 5px #888888;
}

#libraryNameInput,
#surveyNameInput,
#qrcodeNameInput {
  color: #007bff;
  font-size: 1.75rem;
  font-family: 'Roboto';
}

.xs {
  font-size: 1.3em !important;
}

#webcamSession {
  height: 50vh !important;
}

#webcamSession .container {
  height: 100% !important;
}

/* input[type="text"] {
  background: transparent;
  border: none;
} */

.container-tweet-live {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 600px;
}